import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_USE_FILESUSR_DOMAIN,
  EXPERIMENT_FORCE_IFRAME_SANDBOX,
} from '@wix/communities-blog-experiments';
import { getBaseUrl } from '../store/topology/topology-selectors';
import { isInWix } from '../services/is-in-wix';
import { getMetaSiteId } from '../store/instance-values/instance-values-selectors';

export const getIframeSandboxDomain = (state) => {
  const baseUrl = getBaseUrl(state);
  const isSafari =
    typeof navigator !== 'undefined' &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (
    isExperimentEnabled(state, EXPERIMENT_FORCE_IFRAME_SANDBOX) ||
    isInWix() ||
    baseUrl.includes('wixsite.com') ||
    baseUrl.includes('editorx.io') ||
    isSafari
  ) {
    return isExperimentEnabled(state, EXPERIMENT_USE_FILESUSR_DOMAIN)
      ? `https://${getMetaSiteId(state)}.filesusr.com`
      : `https://${getMetaSiteId(state)}.usrfiles.com`;
  }
};
